import { RootState } from "@/redux/Root/store";
import React from "react";
import { useSelector } from "react-redux";

type Props = {
  size: number;
  color?: string;
};

const CircleLoader = ({ size, color = "#000" }: Props) => {
  const state = useSelector((state: RootState) => state.base.ThemeReducer);

  return (
    <div>
      <span className="loader-sp"></span>
      <style>{`
          .loader-sp {
    width: ${size}px;
    height: ${size}px;
    border: 5px solid ${
      state.mode == "light" ? color : state.mode == "dark" ? "#fff" : color
    };
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
          `}</style>
    </div>
  );
};

export default CircleLoader;
